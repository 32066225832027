<template>
    <div class="section-wrapper">
        <base-heading tag="h1" heading="title" subheading="subtitle"  v-animate="`slideLeft`"/>
        <cms-multi-section value="content"/>
    </div>
</template>

<script>
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
export default {
	components: {
		CmsMultiSection,
		BaseHeading
	},
	mixins: [
		pageMixin
	],
	computed: {
		translate () {
			return this.$app.translator.get('download')
		},
		value () {
			return this.$app.page.value
		}
	}
}
</script>

<style lang="scss" scoped>
    .section-wrapper {
        padding: 8rem 0 4rem 0;
        @include media-breakpoint-up(xl) {
            padding: 16rem 0 8rem 0;
        }
        ::v-deep h3 {
            color: $secondary;
        }
        ::v-deep .policy-privacy {
            line-height: 2.6rem;
        }
    }
</style>
